import React, { Component } from 'react';

import styles from './News.module.css';
import OneNews from './OneNews/OneNews';

class News extends Component {
    render() {
        let allNews = <p>Ładowanie aktualności...</p>;

        if (this.props.error) {
            allNews = <p>Nie udało się pobrać aktualności... <i>(Błąd: {this.props.error.message})</i></p>
        }

        if (!this.props.loading && !this.props.error) {
            allNews = this.props.news.map(oneNews => {
                return <OneNews
                    key={oneNews.id}
                    date={oneNews.date}
                    title={oneNews.title}
                    text={oneNews.text} />
            })
        }

        return (
            <div className={styles.News}>
                <h1>Aktualności</h1>
                {allNews}
            </div>
        )
    }
}

export default News;