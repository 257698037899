import React from 'react';

import styles from './TableLine.module.css';

export const TableLine = (props) => {
    return (
        <tr>
            <td>{props.day}</td>
            <td className={styles.EqualColumn}>{props.morningHours}</td>
            <td className={styles.EqualColumn}>{props.eveningHours}</td>
        </tr>
    );
}

export const MergedTableLine = (props) => {
    return (
        <tr>
            <td>{props.day}</td>
            <td colSpan='2'>{props.hours}</td>
        </tr>
    );
}