import React, { Component } from 'react';
import axios from '../../axios-zoovet';

import styles from './NewsCreator.module.css';
import Modal from '../../components/UI/Modal/Modal';

class NewsCreator extends Component {
    state = {
        isLogged: false,
        login: '',
        password: '',
        token: null,
        newPostTitle: '',
        newPostText: '',
        showModal: false,
        modalMessage: ''
    }

    componentDidMount() {
        let idToken = localStorage.getItem('token');
        if (idToken) {
            this.setState({ isLogged: true, token: idToken });
        }
    }

    loginFormHandler = (event) => {
        event.preventDefault();

        let loginData = {
            email: this.state.login,
            password: this.state.password,
            returnSecureToken: true
        }

        axios.post('https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyDg76iU8e_yRWeCYEnt-MyGPbKxKjfNJqo', loginData)
            .then(response => {
                localStorage.setItem('token', response.data.idToken);
                this.setState({
                    isLogged: true,
                    login: '',
                    password: '',
                    token: response.data.idToken
                });
            })
            .catch(error => {
                this.setState({
                    showModal: true,
                    modalMessage: 'Błąd logowania! Spróbuj ponownie.',
                });
            })

    }

    logoutHandler = () => {
        localStorage.removeItem('token');
        this.setState({ isLogged: false, token: null });
    }

    newPostFormHandler = (event) => {
        event.preventDefault();

        let NewPost = {
            date: new Date().toISOString().slice(0, 10),
            title: this.state.newPostTitle,
            text: this.state.newPostText
        };

        axios.post('/news.json?auth=' + this.state.token, NewPost)
            .then(response => {
                console.log(response)
                this.setState({
                    newPostTitle: '',
                    newPostText: '',
                    showModal: true,
                    modalMessage: 'Opublikowano pomyślnie!'
                });
            })
            .catch(error => {
                this.setState({
                    showModal: true,
                    modalMessage: 'Wystąpił błąd! Nie udało się opublikować aktualności, spróbuj ponownie za chwilę...'
                });
            })
    }

    inputValueChangedHandler = (event, name) => {
        if (name === 'login') {
            this.setState({ login: event.target.value });
        }
        if (name === 'password') {
            this.setState({ password: event.target.value });
        }
        if (name === 'title') {
            this.setState({ newPostTitle: event.target.value });
        }
        if (name === 'text') {
            this.setState({ newPostText: event.target.value });
        }
    }

    modalButtonClickedHandler = () => {
        const currentLogin = this.state.login;

        this.setState({
            showModal: false,
            modalMessage: '',
            login: currentLogin,
            password: ''
        });
    }

    render() {
        let loginForm = null;
        let newPostForm = null;

        if (!this.state.isLogged) {
            loginForm = (
                <form className={styles.LoginForm} onSubmit={this.loginFormHandler}>
                    <input
                        className={styles.LoginInput}
                        id='username'
                        placeholder='login'
                        autoFocus={true}
                        value={this.state.login}
                        onChange={(event) => this.inputValueChangedHandler(event, 'login')} />
                    <input
                        className={styles.LoginInput}
                        id='password'
                        type='password'
                        placeholder='hasło'
                        value={this.state.password}
                        onChange={(event) => this.inputValueChangedHandler(event, 'password')} />
                    <button className={styles.LoginButton}>Zaloguj się</button>
                </form>
            )
        }

        if (this.state.isLogged) {
            newPostForm = (
                <div>

                    <form className={styles.NewPostForm} onSubmit={this.newPostFormHandler}>
                        <div className={styles.Row}>
                            <span>Nagłówek nowej aktualności:</span>
                            <textarea
                                value={this.state.newPostTitle}
                                onChange={(event) => this.inputValueChangedHandler(event, 'title')} />
                        </div>
                        <div className={styles.Row}>
                            <span>Treść nowej aktualności:</span>
                            <textarea
                                className={styles.LargeText}
                                value={this.state.newPostText}
                                onChange={(event) => this.inputValueChangedHandler(event, 'text')} />
                        </div>
                        <button className={styles.LoginButton}>Opublikuj</button>
                    </form>
                </div>
            )
        }

        const logout = this.state.isLogged ? (
            <button
                className={styles.LogoutButton}
                onClick={this.logoutHandler}>Wyloguj się</button>) : null;

        return (
            <div className={styles.NewsCreator}>
                {logout}
                <p>Kreator aktualności</p>
                {loginForm}
                {newPostForm}
                <Modal show={this.state.showModal}>
                    <p>{this.state.modalMessage}</p>
                    <button
                        className={styles.LoginButton}
                        onClick={this.modalButtonClickedHandler}>OK</button>
                </Modal>
            </div>
        )
    }
}

export default NewsCreator;