import React from 'react';

import styles from './OpeningHours.module.css';
import { TableLine, MergedTableLine } from './TableLine/TableLine';

const openingHours = () => {
    return (
        <div className={styles.Table}>
            <table>
                <tr>
                    <th></th>
                    <th>godziny otwarcia</th>
                </tr>
                <MergedTableLine day='pon' hours='10:00 - 20:00'/>
                <MergedTableLine day='wt' hours='10:00 - 20:00'/>
                <MergedTableLine day='śr' hours='10:00 - 20:00'/>
                <MergedTableLine day='czw' hours='10:00 - 20:00'/>
                <MergedTableLine day='pt' hours='10:00 - 20:00'/>
                <MergedTableLine day='sob' hours='10:00 - 14:00'/>
                <MergedTableLine day='nd' hours='nieczynne'/>
            </table>
        </div>
    );
}

export default openingHours;