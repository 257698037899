import React from 'react';

import styles from './AboutUs.module.css';
// import zuzaPhoto from '../../assets/images/pm.jpg';

const aboutUs = () => {
    return (
        <div className={styles.AboutUs}>
            <br />
            <div className={styles.Box}>
                <div className={styles.Header}>
                    {/* <img src={zuzaPhoto} /> */}
                    <div className={styles.Person}>
                        <h3>Zuzanna Przygocka (Toczkiewicz)</h3>
                        <span>lekarz weterynarii</span>
                    </div>
                </div>
                <p>
                    Ukończyłam Wydział Medycyny Weterynaryjnej na Uniwersytecie Warmińsko-Mazurskim w Olsztynie.</p>
                <p>
                    Od dzieciństwa otoczona byłam zwierzętami, z ogromnym zainteresowaniem poznawałam ich zwyczaje, charaktery oraz nawiązywałam bardzo dobre relacje. To wspaniałe, kiedy życiowa pasja może przerodzić się w pracę zawodową - i tak właśnie stało się w moim przypadku. Chcąc zrealizować swoje plany zawodowe już od szkoły średniej pracowałam w Klinice Weterynaryjnej jako wolontariuszka, zdobywając wiedzę, praktykę i doświadczenie.</p>
                <p>
                    Po ukończeniu studiów na Wydziale Medycyny Weterynaryjnej podjęłam pracę zawodową w Klinice Weterynaryjnej w Warszawie a następnie w Bydgoszczy. Od 2014 r. prowadzę samodzielny Gabinet Weterynaryjny ZooVet, współpracując z najlepszymi specjalistami w regionie i poza nim. Sama stale doskonalę swą wiedzę i umiejętności biorąc udział w licznych konferencjach, kursach i wykładach specjalistycznych. Podjęłam również studia podyplomowe w celu zdobycia specjalizacji zawodowej.</p>
                <p>
                    A wszystko po to, by jak najlepiej pomagać swoim podopiecznym i ich właścicielom!</p>
            </div>
        </div>
    );
}

export default aboutUs;