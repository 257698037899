import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker/Marker";

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 53.109238,
      lng: 17.999769,
    },
    zoom: 15,
  };

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBhASLGObYbd2-VgyLgagtu6k9MrhbWHT8",
            // key: 'AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo', - klucz testowy
            language: "pl",
            region: "pl",
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={this.props.center.lat} lng={this.props.center.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
