import React from 'react';

import styles from './OneNews.module.css';

const OneNews = (props) => {
    return (
        <div className={styles.OneNews}>
            <p className={styles.Date}>{props.date}</p>
            <p className={styles.Title}>{props.title}</p>
            <div
                className={styles.Text}
                dangerouslySetInnerHTML={{ __html: props.text }} />
        </div>
    )
}

export default OneNews;