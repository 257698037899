import React from 'react';

import styles from './ScopeOfService.module.css';

const scopeOfService = () => {
    return (
        <div className={styles.ScopeOfService}>
            <div className={styles.OneScope}>
                <h3>Profilaktyka:</h3>
                <ul>
                    <li>zapobieganie chorobom zakaźnym - szczepienia ochronne</li>
                    <li>profilaktyka przeciwpasożytnicza (pasożyty wewnętrzne i zewnętrzne)</li>
                    <li>porady żywieniowe i behawioralne</li>
                    <li>zapobieganie chorobom niezakaźnym</li>
                </ul>
            </div>
            <div className={styles.OneScope}>
                <h3>Diagnostyka i terapia chorób:</h3>
                <ul>
                    <li>badania laboratoryjne - badania krwi wykonywane na miejscu, współpraca z wieloma laboratoriami w kraju i za granicą</li>
                    <li>schorzenia dermatologiczne i endokrynologiczne (świąd, alergie, problemy skórne, cytologia i biopsja cienkoigłowa, diagnostyka chorób hormonalnych, posiewy)</li>
                    <li>schorzenia neurologiczne</li>
                    <li>schorzenia narządu wzroku</li>
                    <li>schorzenia układu pokarmowego</li>
                    <li>schorzenia układu oddechowego</li>
                    <li>schorzenia układu rozrodczego</li>
                    <li>schorzenia nerek i dróg wyprowadzających mocz</li>
                    <li>choroby nowotworowe</li>
                    <li>choroby zakaźne</li>
                    <li>stomatologia</li>
                </ul>
            </div>
            <div className={styles.OneScope}>
                <h3>Chirurgia:</h3>
                <ul>
                    <li>chirurgia tkanek miękkich</li>
                    <li>kastracje i sterylizacje</li>
                    <li>pomoc porodowa - cesarskie cięcie</li>
                    <li>chirurgia onkologiczna - usuwanie zmian nowotworowych z możliwością badania histopatologicznego</li>
                    <li>elektrochirurgia - usuwanie zmian za pomocą diatermii</li>
                </ul>
            </div>
            <div className={styles.OneScope}>
                <h3>Implantacja mikroczipów oraz wystawianie paszportów:</h3>
                <ul>
                    <li>znakowanie zwierząt za pomocą mikrotranspondera</li>
                    <li>przygotowanie zwierząt do podróży zagranicznych</li>
                </ul>
            </div>
            <div className={styles.OneScope}>
                <h3>Badania laboratoryjne:</h3>
                <ul>
                    <li>badanie krwi</li>
                    <li>badanie moczu</li>
                    <li>badanie kału</li>
                    <li>badanie cytologiczne i histopatologiczne</li>
                    <li>badanie płynów ustrojowych</li>
                    <li>badania genetyczne</li>
                    <li>posiewy bakteryjne i mykologiczne</li>
                    <li>diagnostyka alergii</li>
                </ul>
            </div>
            <div className={styles.OneScope}>
                <h3>Badania USG</h3>
            </div>
            <div className={styles.OneScope}>
                <h3>Wizyty domowe</h3>
                wizyty domowe można rezerwować pod numerem tel. <strong>788 831 013</strong>
            </div>
        </div>
    );
}

export default scopeOfService;