import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import axios from './axios-zoovet';

import './App.css';
import Layout from './hoc/Layout/Layout';
import { ROUTES } from './shared/utility';

import AboutUs from './components/AboutUs/AboutUs';
import ScopeOfService from './components/ScopeOfService/ScopeOfService';
import OpeningHours from './components/OpeningHours/OpeningHours';
import Contact from './components/Contact/Contact';
import News from './containers/News/News';
import NewsCreator from './containers/NewsCreator/NewsCreator';

class App extends Component {
  state = {
    news: [],
    loading: true,
    error: null
  }

  componentDidMount() {
    axios.get('/news.json')
      .then(response => {
        const fetchedNews = [];
        for (let key in response.data) {
          fetchedNews.push({
            ...response.data[key],
            id: key
          });
        }

        fetchedNews.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })

        this.setState({ loading: false, news: fetchedNews });
      })
      .catch(err => {
        this.setState({loading: false, error: err});
      });
  }

  render() {

    let routes = (
      <Switch>
        <Route path={ROUTES.oNas} component={AboutUs} />
        <Route path={ROUTES.zakresUslug} component={ScopeOfService} />
        <Route path={ROUTES.godzinyOtwarcia} component={OpeningHours} />
        <Route path={ROUTES.kontakt} component={Contact} />
        <Route path={ROUTES.kreatorNewsow} component={NewsCreator} />
        <Route path={ROUTES.stronaGlowna} exact render={() => <News {...this.state} />} />
        <Redirect to='/' />
      </Switch>
    );

    return (
      <Layout>
        {routes}
      </Layout>
    );
  }
}

export default App;
