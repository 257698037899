import React from "react";

import styles from "./Contact.module.css";
import ContactDetail from "./ContactDetail/ContactDetail";
import email from "../../assets/images/email.png";
import facebook from "../../assets/images/facebook.png";
import localization from "../../assets/images/localization.png";
import phone from "../../assets/images/phone.png";
import GoogleMap from "../../containers/GoogleMap/GoogleMap";

const contact = () => {
  let firstColumnStyle = [styles.Column, styles.FirstColumn].join(" ");
  let secondColumnStyle = [styles.Column, styles.SecondColumn].join(" ");

  return (
    <div className={styles.Contact}>
      <div className={firstColumnStyle}>
        <ContactDetail
          image={localization}
          title="ADRES"
          extraLine="85-154 Bydgoszcz"
        >
          ul. Brzozowa 19
        </ContactDetail>
        <ContactDetail image={phone} title="TELEFON">
          788 831 013
        </ContactDetail>
        <ContactDetail image={email} title="EMAIL">
          <a href="mailto:kontakt@zoovet.bydgoszcz.pl">
            <span>kontakt@zoovet.bydgoszcz.pl</span>
          </a>
        </ContactDetail>
        <ContactDetail image={facebook} title="FACEBOOK">
          <a href="https://www.facebook.com/gabinetzoovet/">
            <span>link do Fanpage'a</span>
          </a>
        </ContactDetail>
      </div>
      <div
        className={secondColumnStyle}
        style={{ "background-color": "lightblue", height: "500px" }}
      >
        <GoogleMap />
      </div>
    </div>
  );
};

export default contact;
