import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavigationItem.module.css';

const navigationItem = (props) => {
    return (
        <li className={styles.NavigationItem} onClick={props.closed}>
            <NavLink
                to={props.link}
                activeClassName={styles.active}
                exact={props.exact}>
                <div>
                    {props.children}
                </div>
            </NavLink>
        </li>
    );
}

export default navigationItem;