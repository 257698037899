import React from 'react';
import styles from './Marker.module.css';

const marker = () => {
    return (
        <div className={styles.test}>
            <div className={styles.test2} />
        </div>
    )
}

export default marker;