import React from 'react';

import styles from './ContactDetail.module.css';

const ContactDetail = (props) => {
    let extraLine = props.extraLine ? <p className={styles.Line}>{props.extraLine}</p> : null;
    return (
        <div className={styles.ContactDetail}>
            <img src={props.image} />
            <div className={styles.ContactText}>
                <p className={styles.Title}>{props.title}</p>
                {props.children}
                {extraLine}
            </div>
        </div>
    );
}

export default ContactDetail;