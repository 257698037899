import React from 'react';

import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { ROUTES } from './../../../shared/utility';

const navigationItems = (props) => {
    return (
        <ul className={styles.NavigationItems}>
            <NavigationItem closed={props.clicked} link={ROUTES.stronaGlowna} exact>Strona główna</NavigationItem>
            <NavigationItem closed={props.clicked} link={ROUTES.oNas}>O nas</NavigationItem>
            <NavigationItem closed={props.clicked} link={ROUTES.zakresUslug}>Zakres usług</NavigationItem>
            <NavigationItem closed={props.clicked} link={ROUTES.godzinyOtwarcia}>Godziny otwarcia</NavigationItem>
            <NavigationItem closed={props.clicked} link={ROUTES.kontakt}>Kontakt</NavigationItem>
        </ul>
    );
}

export default navigationItems;